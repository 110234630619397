import {authToken} from "~/store/cookie";

export default {

    authToken: {

        value: () => {
            return authToken.value;
        },

        setValue: (value) => {
            authToken.value = value;
        },

    }

}