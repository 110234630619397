// noinspection JSUnusedGlobalSymbols

import config from "./config";
import cookie from "~/utils/cookie";
import qs from "qs";

interface ErrorError {
    [index: string]: Array<string>
}

interface Error {
    message: string|null,
    errors: ErrorError
}

interface SuccessCallable extends Function {
    (data: Object|any): void;
}

interface ErrorCallable extends Function {
    (error: Error): void;
}

export class ApiRequest {
    _apiUrl = null;
    _method = null;
    _uri = null;
    _query = null;
    _data = null;

    _onSuccess:         Array<Function> = [];
    _onServerError:     Array<Function> = [];
    _onValidationError: Array<Function> = [];
    _onNotFoundError:   Array<Function> = [];
    _onForbiddenError:  Array<Function> = [];
    _onUnAuthenticate:  Array<Function> = [];
    _onAnotherError:    Array<Function> = [];
    _onError:           Array<Function> = [];

    _promise = null;

    constructor(method: string, uri: string, query: object|null = null, data:object|null = null) {
        // noinspection TypeScriptUnresolvedReference

        this.setMethod(method);
        this.setUri(uri);
        this.setQuery(query);
        this.setData(data);
        this.pushEvents();
    }

    pushEvents() : void {
        this.onServerError((error) => {
            console.error(error)
        })
    }

    async _request() : Promise<object|null> {

        // noinspection JSUnresolvedReference
        this._apiUrl = config().apiUrl;

        let url = `${this._apiUrl}/${this.getUri()}`;

        if (this.getQuery() !== null) {
            url += `?${this.getQuery()}`;
        }

        let opts = {
            method: this.getMethod(),
            body: this.getData(),
            headers: {
                Authorization: '',
            }
        }

        if (cookie.authToken.value()) {
            opts.headers.Authorization = 'Bearer ' + cookie.authToken.value()
        }
        // console.log(opts);

        let data = null;

        try {
            // noinspection TypeScriptValidateTypes
            data = await $fetch(url, opts)

            this._onSuccess.forEach((callable: Function) => callable(data));

        } catch (e) {
            console.error(e);
            let error: Error = {
                message: null,
                errors: {}
            }

            if (e.data) {
                error.message = e.data.message ?? null;
                error.errors = e.data.errors ?? {};
            }

            if (e.status === 401) {
                this._onUnAuthenticate.forEach((callable: Function) => callable(error));
            } else if (e.status === 403) {
                this._onForbiddenError.forEach((callable: Function) => callable(error));
            } else if (e.status === 404) {
                this._onNotFoundError.forEach((callable: Function) => callable(error));
            }  else if (e.status === 422) {
                this._onValidationError.forEach((callable: Function) => callable(error));
            } else if (e.status >= 500) {
                this._onServerError.forEach((callable: Function) => callable(error));
            } else {
                this._onAnotherError.forEach((callable: Function) => callable(error));
            }

            this._onError.forEach((callable: Function) => callable(error));

        }

        return await data;
    }

    async request(usePromise = false) : Promise<object|null> {

        if (!usePromise) {
            return this._request();
        }

        if (this._promise !== null) {
            return this._promise;
        }

        this._promise = new Promise((resolve) => {
            resolve(this._request());
        });

        return this._promise;
    }

    onSuccess(callable: SuccessCallable) {
        this._onSuccess.push(callable)
        return this;
    }
    onServerError(callable: ErrorCallable) {
        this._onServerError.push(callable)
        return this;
    }
    onValidationError(callable: ErrorCallable) {
        this._onValidationError.push(callable)
        return this;
    }
    onNotFoundError(callable: ErrorCallable) {
        this._onNotFoundError.push(callable)
        return this;
    }
    onForbiddenError(callable: ErrorCallable) {
        this._onForbiddenError.push(callable)
        return this;
    }
    onUnAuthenticate(callable: ErrorCallable) {
        this._onUnAuthenticate.push(callable)
        return this;
    }
    onAnotherError(callable: ErrorCallable) {
        this._onAnotherError.push(callable)
        return this;
    }
    onError(callable: ErrorCallable) {
        this._onError.push(callable)
        return this;
    }

    getMethod() : string|null {
        return this._method;
    }

    setMethod(value: string|null): this {
        this._method = value;

        return this;
    }

    getUri() : string|null {
        return this._uri;
    }

    setUri(uri: string|null): this {
        this._uri = null;
        if (uri) {
            this._uri = uri.trim().replace(`^/`, '').replace(`/$`, '')
        }

        return this;
    }

    getQuery(): object|null {
        return this._query;
    }

    setQuery(query: object|null) : this {

        if (query === null) {
            return this;
        }

        if (Object.keys(query) === 0) {
            return this;
        }

        this._query = qs.stringify(query, { encode: false })

        return this;
    }

    getData() : object|null {
        return this._data;
    }

    setData(data: object|null) : this {
        this._data = null;

        if (data !== null) {
            this._data = data;
        }

        this._data = data;

        return this;
    }
}